import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --pink: #e9619a;
    --black: #212121;
    --green: #85ebb0;
    --blue: #6c63ff;
    --red: #FFBBBB;
    --one: #8e38ff;
    --two: #B983FF;
    --three: #94B3FD;
    --four: #94DAFF;
    --five: #99FEFF;
    --a: #4C3F91;
    --b: #9145B6;
    --c: #B958A5;
    --d: #FF5677;
    --e: #e57e92;
    --f: #d2eda6;
    --bg-theme: rgba(0, 0, 0, 0.5);

    scroll-padding-top: 10rem;

    *::selection {
      background: var(--pink);
      color: #fff;
    }

    header.header-fixed{
      transition: 0.5s;
      a {
        transition: 0.5s;
      }
    }
    footer.footer {
      transition: 0.5s;
    }
    form {
      input, textarea {
        transition: 0.5s;
      }
    }
    .nav-active {
      border-bottom: 1px solid #fafafe;
    }

    &.light {
    --bg-theme: rgba(200, 200, 200, 0.5);
      body {
        background-color: #f5f5f5;
        color: var(--black);
      }

      .nav-active {
        border-bottom: 1px solid var(--black);
      }

      .vertical-timeline-element-date {
        color: var(--black);
      }
      
      .header-logo {
        filter: invert(0);
      }

      .logo {
        color: var(--black);
      }

      header.header-fixed {
        background-color: #f5f5f550;
        a {
          color: black;
        }
        .menu,.menu:before, .menu:after {
          background-color: var(--black); 
        }
        .menu.active {
          background-color: rgba(555,555,555,0);
        }
      }

      .project {
        color: var(--black);
      }

      footer.footer {
        background-color: rgba(0,0,0,0.1);
        color: var(--black);
      }

      form {
        input, textarea {
          border: solid 1px var(--black);
          color: var(--black);
          &::placeholder {
            transition: 0.5s;
            color: var(--black);
          }
        }
      }

      /* .swiper-button-prev, .swiper-button-next {
        color: var(--pink);
      } */
    }
  }

  @media (min-width: 961px) {
    .section-row {
      display: flex;
      justify-content: space-between;
      gap: 5%;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    transition: color 0.5s;
    color: var(--pink);
    margin-top: -58.5px;
  }

  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
    transition: background-color 0.5s;
  }

  body, input, textarea, button {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  button, .button {
    border: none;
    cursor: pointer;
    background-color: var(--green);
    color: #FFFF;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    &:hover{
      filter: brightness(0.8);
    }
  }

  button:disabled, .button:disabled {
    filter: brightness(0.8);
    cursor: not-allowed;
  }


  .logo {
    font-size: 3rem;
    color: #FFFF;
    &::first-letter {
      color: var(--green);
    }
  }
`;
